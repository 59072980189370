<template>
    <section class="forms">
        <div class="page-header">
            <h3 class="page-title">
                <!-- Set Discount on User -->
                Send Promocode
            </h3>
            <button type="button" class="btn btn-info believe-btn" @click="goBack()">
                <i class="mdi mdi-keyboard-backspace"></i> Back</button>

            <!-- <router-link class="btn btn-primary believe-btn" :to="`/hypnosis/list/${current_category_id}`">
            <i class="mdi mdi-keyboard-backspace"></i> Back</router-link> -->
            <!-- <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">hypn</a></li>
          <li class="breadcrumb-item active" aria-current="page">Basic</li>
        </ol>
      </nav> -->
        </div>
        <div class="row">
            <div class="col-md-7 m-auto d-flex align-items-stretch grid-margin">
                <div class="row flex-grow">
                    <div class="col-12 grid-margin">
                        <div class="card">
                            <div class="card-body">
                                <!-- <h4 class="card-title">Default form</h4>
                                <p class="card-description">
                                    Basic form layout
                                </p> -->
                                <b-form @submit.stop.prevent="onSubmit">
                                    <div class="row">
                                        <!-- <div class="col-md-12">
                                            <b-form-group id="example-input-group-1" label="Enter promo code title"
                                                label-for="example-title">
                                                <b-form-input id="example-title" name="example-title"
                                                placeholder="Enter Title"
                                                    v-model="$v.form.title.$model"
                                                    :state="$v.form.title.$dirty ? !$v.form.title.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>
                                            </b-form-group>
                                        </div> -->

                                        <div class="col-md-12">
                                            <b-form-group id="example-input-group-1"
                                                label="Select Promocode"
                                                label-for="example-input-1">

                                                <!-- <select>
                                                    <option selected disabled>Select Promocode</option>
                                                    <option v-for="promo in promocodes" :value="promo.id">{{ promo.code }}</option>
                                                </select> -->

                                                <b-form-select v-model="$v.promocode_id.$model" value-field="id"
                                                    text-field="title">
                                                    <option :value="null" selected disabled>-- Please select promocode --
                                                    </option>
                                                    <option v-for="promo in promocodes" :value="promo.id">
                                                            {{ promo.code }}
                                                    </option>
                                                </b-form-select>

                                                <!-- <b-form-input id="example-input-1" name="example-input-1" type="number"
                                                    min="1" max="99" placeholder="For example: '30'"
                                                    v-model="$v.form.name.$model"
                                                    :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input> -->

                                                <!-- <div v-if="$v.form.name.$error" class="invalid-feedback">
                                                    <div v-if="!$v.form.name.required">Promocode is required</div> -->
                                                    <!-- <div v-if="!$v.form.name.decimal">Discount should be in numbers</div> -->
                                                    <!-- <div v-if="!$v.form.name.between">Discount should be between 1 to 99</div> -->
                                                <!-- </div> -->
                                            </b-form-group>
                                        </div>

                                        <!-- <div class="col-md-12 my-2">
                                            <label for="example-plan">Select Subscription Plan : &nbsp;</label>
                                            Monthly <toggle-button v-model="form.subscription_plan_id"
                                                @change="changePlan" /> Yearly
                                        </div> -->

                                        <!-- <div class="col-md-12">
                                            <b-form-group id="example-input-group-1" label="Author"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    v-model="$v.form.author.$model"
                                                    :state="$v.form.author.$dirty ? !$v.form.author.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>
                                            </b-form-group>
                                        </div> -->
                                    </div>








                                    <!-- <b-form-group id="example-input-group-1" label="Description"
                                        label-for="example-input-1">
                                        <b-form-textarea id="example-input-1" name="example-input-1"
                                            v-model="$v.form.description.$model"
                                            :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
                                            aria-describedby="input-1-live-feedback" placeholder="Enter something..."
                                            rows="3" max-rows="6"></b-form-textarea>
                                    </b-form-group> -->

                                    <b-button :disabled='is_loading' type="submit" variant="success"
                                        class="btn-block believe-btn">
                                        <i
                                            :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                        Submit
                                    </b-button>
                                </b-form>
                                <vue-snotify></vue-snotify>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { json } from 'body-parser';
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import api from "../../../config/api.js";
import Tag from "@/components/Tag.vue";
import AudioFile from "@/components/AudioFile.vue";
import { VueEditor } from "vue2-editor";
import { decimal, between } from 'vuelidate/lib/validators'
// import simpleSnotifysuccess from '@/components/alerts/snotify/simpleSnotifysuccess'
export default {
    name: 'AudioAddModal',
    mixins: [validationMixin],
    data() {
        return {
            count: 0,
            msg: "",
            is_loading: false,
            user_id: "",
            promocode_id : "",
            promocodes : [],
            // form: {
            //     text: null,
            //     name: null,
            //     description: null,
            //     subscription_plan_id: null,
            // }
        }
    },

    mounted() {
        this.items();
    },

    components: {
        VueEditor,
        Tag,
        AudioFile
        // simpleSnotifysuccess
    },
    validations: {
        promocode_id:{
            required
        },
        // form: {
            // name: {
            //     required,
            //     decimal,
            //     between: between(1, 99)
            // },
            // title: {
            //     required,
            // },
        // }
    },

    methods: {

        // changePlan() {
        //     if (this.form.subscription_plan_id) {
        //         this.form.subscription_plan_id = 2
        //     } else {
        //         this.form.subscription_plan_id = 1
        //     }
        // },

        async items() {
            let result = await api.get("/admin/all-promo");
            if (result.status == 200) {
                this.promocodes = result.data.all_promo
                this.promocodes = this.promocodes.filter((arr)=>arr.status == "Active")
                console.log(this.promocodes);
            }
            let data = await this.$store.getters['getEditFormData'];
            if (data.length == 0) {
                data = localStorage.getItem('data_for_edit');
                data = JSON.parse(data);
            }
            this.user_id = data?.id;
        },

        goBack() {
            // let prev_url = '/users/list';
            let prev_url = this.$store.state.prev_url;
            if (prev_url == null || prev_url == "") {
                prev_url = localStorage.getItem('prev_url');
            }
            this.$router.push({ path: prev_url });
        },





        async onSubmit() {
            this.$v.promocode_id.$touch()
            if (this.$v.promocode_id.$anyError) {
                return false;
            }

            try {

                // let sub_id = "";
                // if (this.form?.subscription_plan_id == null) {
                //     sub_id = 1;
                // } else {
                //     sub_id = this.form?.subscription_plan_id;
                // }

                this.is_loading = true;
                // let obj = this.$store.getters['getEditFormData'];
                let endpoint = "/admin/generate-promo-code-link";
                const formData = new FormData();
                // formData.append("discount_percent", this.form?.name);
                formData.append("promocode_id", this.promocode_id);
                formData.append("user_id", this.user_id);
                // formData.append("subscription_plan_id", sub_id);
                let result = await api.post(endpoint, formData);
                if (result.status == 200) {
                    // await result.data.message;
                    const msg = "Promocode has been sent"
                    // alert(msg);
                    this.$store.dispatch('setMessage', { msg });
                    // await this.$store.dispatch(`users/all_after_submit`);
                    
                    let prev_url = this.$store.state.prev_url;
                    if (prev_url == null || prev_url == "") {
                        prev_url = localStorage.getItem('prev_url');
                    }
                    this.$router.push({ path: prev_url });

                    // this.is_loading = false;
                }
            } catch (error) {
                console.log(error);
                // this.progress = 0;
                // let err = error.response.data.message;
                // this.$store.dispatch('setMessage', { err });
                // this.showSnotifyError()
                // this.$toast.error(this.error);
            }
        },


        showSnotifySuccess(msg) {
            let obj = this.$store.getters['getMessage'];
            this.$snotify.success(obj.msg, {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
            });
        },
        showSnotifyError(msg) {
            let obj = this.$store.getters['getMessage'];
            this.$snotify.error(obj.msg, {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
            });
        },

    },




}
</script>